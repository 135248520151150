import React from "react";
import { Header, Icon, Grid, Button, Container, Tab } from "semantic-ui-react";
import { CONTAINER_STYLE } from "../../../shared/styles";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { ComponentProps } from "../../../shared/ComponentProps";
import User from "../../../models/User";
import connectAllProps from "../../../shared/connect";
import SyntaxHighlighter from "react-syntax-highlighter";
import { vs2015 } from "react-syntax-highlighter/dist/esm/styles/hljs";

interface States {}
class Appointment extends React.Component<ComponentProps, States> {
    render() {
        const user: User | undefined = this.props.state.userState.currentUser;
        return <Container style={CONTAINER_STYLE}>
            <Grid stackable>
                <Grid.Row style={{ minHeight: 30 }}></Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={3}></Grid.Column>
                    <Grid.Column width={10} verticalAlign="bottom">
                        <Header textAlign="center" size="huge">
                            <div>
                                <Icon name="calendar check"></Icon>
                            </div>
                            <span className="punchLine">Bookings &amp; Appointments Component</span>
                            <Header.Subheader className="punchLineSubHeader">
                                Most comprehensive and fully featured booking and appointment component that you can add to your project in seconds, all completely FREE!
                            </Header.Subheader>
                        </Header>
                        <Header textAlign="center" size="huge">
                            <Header.Subheader className="punchLineSubHeader">
                                <Link to={user ? "/calendars/" : "/login/"}>
                                    <Button animated="fade" style={{ marginTop: 15, marginBottom: 20, borderRadius: 30, padding: "15px 20px 15px 20px" }} color="black">
                                        <Button.Content visible>Go to console</Button.Content>
                                        <Button.Content hidden>
                                            <Icon name="terminal" />
                                        </Button.Content>
                                    </Button>
                                </Link>
                                <Link to={user ? "/calendars/" : "/join/"}>
                                    <Button animated="fade" style={{ marginTop: 15, marginBottom: 20, borderRadius: 30, padding: "15px 20px 15px 20px" }} color="orange">
                                        <Button.Content visible><FormattedMessage id="get_started" /></Button.Content>
                                        <Button.Content hidden>
                                            <Icon name="arrow right" />
                                        </Button.Content>
                                    </Button>
                                </Link>
                            </Header.Subheader>
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={3}></Grid.Column>
                </Grid.Row>
                <Grid.Row style={{ minHeight: 100 }}></Grid.Row>
                <Grid.Row columns={4}>
                    <Grid.Column width={3}></Grid.Column>
                    <Grid.Column width={10}>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={4}>
                                    <img src="/images/templates/bubble_io.png" width={30} />
                                    <h4>Build with no-code tools</h4>
                                    <p>
                                        Build with popular no-code tools such as Bubble and Retool, or even web-builders such as Squarespace and Wordpress.
                                    </p>
                                </Grid.Column>
                                <Grid.Column width={4}>
                                    <i className={"fa-brands fa-react fa-2x"}></i>
                                    <h4>Switch between coding languages you like</h4>
                                    <p>
                                        Need to transition to another no-code tools or programming languages? Your component works across languages such as React and Next.js so you can jump between different development tools and support various devices without any downtime.
                                    </p>
                                </Grid.Column>
                                <Grid.Column width={4}>
                                    <i className={"fa-regular fa-calendar fa-2x"}></i>
                                    <h4>Sync with your own calendar</h4>
                                    <p>
                                        Use your own calendar services such as Google Calendar or Microsoft Outlook to manage and update availabilities, and AirJam will update your resource's availabilities to and from your calendar automatically.
                                    </p>
                                </Grid.Column>
                                <Grid.Column width={4}>
                                    <i className={"fa-solid fa-credit-card fa-2x"}></i>
                                    <h4>Manage using admin console</h4>
                                    <p>
                                        Manage payments, moderation, and so much more from the admin console.
                                    </p>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                    <Grid.Column width={3}></Grid.Column>
                </Grid.Row>

                <Grid.Row style={{ minHeight: 100 }}></Grid.Row>

                <Grid.Row columns={4}>
                    <Grid.Column width={2}></Grid.Column>
                    <Grid.Column width={5}>
                        <img src="/images/view_table_spreadsheets.png" alt="" />
                    </Grid.Column>
                    <Grid.Column width={7} verticalAlign="bottom">
                        <Header as="h1" textAlign="left" size="large" style={{ width: "100%" }}>
                            Add your own Calendly features that actually work
                            <Header.Subheader className="subheader_more_margin_top">
                                Choose the component that's actually designed to work in real life!
                                AirJam's goal is to bring you components that are flexible enough to handle various real-life scenarios while giving you controls that you actually need to operate components in real-life.
                            </Header.Subheader>
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={2}></Grid.Column>
                </Grid.Row>
                <Grid.Row style={{ minHeight: 100 }}></Grid.Row>
                <Grid.Row columns={4}>
                    <Grid.Column width={2}></Grid.Column>
                    <Grid.Column width={7} verticalAlign="bottom">
                        <Header as="h1" textAlign="right" size="large" style={{ width: "100%" }}>
                            Full power of React.JS and Next.JS when you're ready
                            <Header.Subheader className="subheader_more_margin_top">
                                AirJam is the first component that supports anything from Wordpress to Bubble to React.JS, so you can switch between various platforms without having to worry about rebuilding the booking feature all over again.
                                Build no-code apps using Bubble, and mobile apps with React Native, and your booking features will work synchronously across your platforms.
                            </Header.Subheader>
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={5}>
                        <img src="/images/view_table_stickers.png" alt="" />
                    </Grid.Column>
                    <Grid.Column width={2}></Grid.Column>
                </Grid.Row>
                <Grid.Row style={{ minHeight: 100 }}></Grid.Row>
                <Grid.Row columns={4}>
                    <Grid.Column width={2}></Grid.Column>
                    <Grid.Column width={5}>
                        <img src="/images/view_table_spreadsheets.png" alt="" />
                    </Grid.Column>
                    <Grid.Column width={7} verticalAlign="bottom">
                        <Header as="h1" textAlign="left" size="large" style={{ width: "100%" }}>
                            Fully featured admin interface
                            <Header.Subheader className="subheader_more_margin_top">
                                Airjam's booking component comes with admin interface that lets you control things like operating hours, localization, messaging, and booking request moderation. Need these features in your own admin system? The admin system itself is also a component that you can embed to your Bubble or React projects.
                            </Header.Subheader>
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={2}></Grid.Column>
                </Grid.Row>
                <Grid.Row style={{ minHeight: 100 }}></Grid.Row>
                <Grid.Row columns={4}>
                    <Grid.Column width={2}></Grid.Column>
                    <Grid.Column width={7} verticalAlign="bottom">
                        <Header as="h1" textAlign="right" size="large" style={{ width: "100%" }}>
                            Add your own branding with AirJam
                            <Header.Subheader className="subheader_more_margin_top">
                                Airjam is designed to give you as much power as possible over your own styling and branding choices. Airjam's React and Next.JS components let you override flows and logic with custom rendering functions and CSS stylings,
                                and AirJam's admin console lets you change any built-in messages with its localization panel and its email template editors.
                            </Header.Subheader>
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={5}>
                        <img src="/images/view_table_stickers.png" alt="" />
                    </Grid.Column>
                    <Grid.Column width={2}></Grid.Column>
                </Grid.Row>
                <Grid.Row style={{ minHeight: 100 }}></Grid.Row>

            </Grid>


        </Container>;
    }
}

export default connectAllProps(Appointment);